import { zodResolver } from '@hookform/resolvers/zod';
import { routeAnimationVariants } from '@public-pre-invite/router';
import { UserData, userDataAtom } from '../../../../../../atom/preInvite.atom';
import { postValidateRegistrationNumberService } from '@public-pre-invite/services/auth.service';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { InfoIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { EnrollmentDetailsModal } from './components/EnrollmentDetailsModal';
import { Alert, AlertDescription, AlertTitle, Button, EInput, Form, FormInput } from '@eyecarehealth/ds-aquilae-react';

type RegistrationNumberProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<'document' | 'enrollment'>>;
};

const formSchema = z.object({
  registrationNumber: z.string(),
});

export function RegistrationNumber({ setCurrentStep }: RegistrationNumberProps) {
  const methods = useForm({
    resolver: zodResolver(formSchema),
  });
  const [loading, setLoading] = useState(false);
  const [triedToSubmit, setTriedToSubmit] = useState(0);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);

  async function validateRegistrationNumber(data: z.infer<typeof formSchema>) {
    setLoading(true);
    try {
      const response = await postValidateRegistrationNumberService({
        companyId: userData.selectedCompany.id,
        registrationNumber: data.registrationNumber,
      });
      setUserData({ ...userData, registrationNumber: data.registrationNumber });
      setCurrentStep('document');
    } catch (error) {
      setLoading(false);
      methods.setError('enrollment', { message: error[0] });
      setTriedToSubmit((prev) => prev + 1);
      console.error(error);
    }
  }

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">
        {userData?.selectedCompany?.inviteCustomLabel || 'Insira seu número de matrícula'}
      </h2>
      <span className="text-typography-secondary text-sm">Vamos usar essa informação para confirmar que você foi adicionado ao nosso benefício.</span>

      <div className="mt-4 max-w-[405px]">
        <Form ctx={methods} onSubmit={validateRegistrationNumber}>
          <FormInput name="registrationNumber" label="" placeholder="Digite aqui" type={EInput.TEXT} />

          {!userData?.selectedCompany?.inviteCustomLabel && <EnrollmentDetailsModal />}

          {triedToSubmit > 2 && (
            <Alert className="mb-4">
              <InfoIcon size={20} className="text-brand-primary" />
              <AlertTitle className="text-brand-primary">Não conseguimos confirmar sua elegibilidade</AlertTitle>
              <AlertDescription>
                Certifique-se de que:
                <br />
                1. Você selecionou a empresa certa.
                <br />
                2. Você preencheu suas informações corretamente.
                <br />
                3. Sua empresa já adicionou você ao Benefício Visão.
                <br />
                Se o problema persistir, entre em contato conosco <a href="mailto:suporte@beneficiovisao.com.br">(suporte@beneficiovisao.com.br)</a>
              </AlertDescription>
            </Alert>
          )}

          <Button type="submit" className="w-full" loading={loading}>
            Continuar
          </Button>
        </Form>
      </div>
    </motion.div>
  );
}
