import BVUtils from '@bv/utils';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@eyecarehealth/ds-aquilae-react';
import { useAtom } from 'jotai';
import { BuildingIcon } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserData, userDataAtom } from '../../../../atom/preInvite.atom';
import { GetValidateCompanyResponse } from '../../../../services/company.service';

type CompanyDetailsModalProps = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCompany: GetValidateCompanyResponse;
};

export function CompanyDetailsModal({ modal, setModal, selectedCompany }: CompanyDetailsModalProps) {
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const navigate = useNavigate();

  if (BVUtils.device.IS_MOBILE) {
    return (
      <Drawer open={modal} onOpenChange={setModal}>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>
              <div className="flex h-8 w-8 items-center justify-center rounded bg-gray-100">
                <BuildingIcon size={20} className="text-brand-secondary" />
              </div>
            </DrawerTitle>
            <DrawerDescription className="text-left">Veja os detalhes da empresa selecionada.</DrawerDescription>
          </DrawerHeader>
          <div className="space-y-4 p-4">
            <div className="flex flex-col gap-1">
              <span className="text-typography-secondary text-sm">Nome Comercial</span>
              <strong className="text-typography">{selectedCompany?.name}</strong>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-typography-secondary text-sm">Razão Social</span>
              <strong className="text-typography">{selectedCompany?.corporateName}</strong>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-typography-secondary text-sm">CNPJ</span>
              <strong className="text-typography">{selectedCompany?.document}</strong>
            </div>
          </div>
          <DrawerFooter>
            <Button
              onClick={() => {
                setUserData({ ...userData, selectedCompany });
                navigate('/identificador');
              }}>
              Selecionar
            </Button>
            <Button variant="ghost" onClick={() => setModal(false)}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Dialog open={modal} onOpenChange={setModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <div className="flex h-8 w-8 items-center justify-center rounded bg-gray-100">
              <BuildingIcon size={20} className="text-brand-secondary" />
            </div>
          </DialogTitle>
          <DialogDescription>Veja os detalhes da empresa selecionada.</DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="flex flex-col gap-1">
            <span className="text-typography-secondary text-sm">Nome Comercial</span>
            <strong className="text-typography">{selectedCompany?.name}</strong>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-typography-secondary text-sm">Razão Social</span>
            <strong className="text-typography">{selectedCompany?.corporateName}</strong>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-typography-secondary text-sm">CNPJ</span>
            <strong className="text-typography">{selectedCompany?.document}</strong>
          </div>
        </div>
        <DialogFooter>
          <Button variant="ghost" onClick={() => setModal(false)}>
            Fechar
          </Button>
          <Button
            onClick={() => {
              setUserData({ ...userData, selectedCompany });
              navigate('/identificador');
            }}>
            Selecionar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
