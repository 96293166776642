import { routeAnimationVariants } from '@public-pre-invite/router';
import { AnimatePresence, motion } from 'framer-motion';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import BVUtils from '@bv/utils';
import { InfoIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { UserData, userDataAtom } from '../../atom/preInvite.atom';
import { postValidateOtpService, postSendOtpService } from '@public-pre-invite/services/auth.service';
import { Alert, AlertDescription, AlertTitle, Button, EInput, Form, FormInput } from '@eyecarehealth/ds-aquilae-react';
import { useMutation } from '@tanstack/react-query';
import { POST_VALIDATE_OTP } from '@public-pre-invite/consts/queries';

const formSchema = z.object({
  otp: z.string().min(6, { message: 'Campo obrigatório' }),
});

export default function OTPValidationPage() {
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();
  const [triedToSubmit, setTriedToSubmit] = useState(0);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  let interval = useRef(null);

  const { mutate: validateOtp, isPending: isOtpValidating } = useMutation({
    mutationKey: [POST_VALIDATE_OTP],
    mutationFn: (data: z.infer<typeof formSchema>) => postValidateOtpService({ email: userData.email, code: data.otp }),
    onSuccess: (data: { inviteUrl: string }) => {
      setUserData({ ...userData, inviteUrl: data.inviteUrl });
      navigate('/sucesso');
    },
    onError: (error) => {
      methods.setError('otp', { message: 'Certifique-se de que ele é igual ao que te enviamos. Você pode reenviar o código mais 2 vezes.' });
      setTriedToSubmit((prev) => prev + 1);
    },
  });

  async function resendOTP() {
    try {
      await postSendOtpService({ email: userData.email, companyId: userData.selectedCompany.id });

      startTimer();
    } catch (error) {
      BVUtils.toastHandler({ type: 'error', title: 'Erro ao reenviar código', message: 'Algo deu errado, tente novamente mais tarde.' });
    }
  }

  function startTimer() {
    setTimer(60);
    setCanResend(false);

    interval.current = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  }

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(interval.current);
      setCanResend(true);
    }
  }, [timer]);

  return (
    <motion.div
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}
      animate="final"
      initial="initial"
      key={'otpValidation'}
      variants={routeAnimationVariants}>
      <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">Código de validação</h2>
      <span className="text-typography-secondary text-sm">
        Enviamos para o email <strong>{userData.email}</strong>
      </span>

      <div className="mt-4 max-w-[405px]">
        <Form ctx={methods} onSubmit={validateOtp}>
          <FormInput name="otp" label="" type={EInput.OTP} className="mb-20 h-16 text-2xl" />
          {triedToSubmit > 2 && (
            <Alert className="mb-4" variant="info">
              <InfoIcon size={20} className="text-brand-primary" />
              <AlertTitle className="text-brand-primary">Não conseguimos confirmar sua elegibilidade</AlertTitle>
              <AlertDescription>
                Certifique-se de que:
                <br />
                1. Você selecionou a empresa certa.
                <br />
                2. Você preencheu suas informações corretamente.
                <br />
                3. Sua empresa já adicionou você ao Benefício Visão.
                <br />
                Se o problema persistir, entre em contato conosco <a href="mailto:suporte@beneficiovisao.com.br">(suporte@beneficiovisao.com.br)</a>
              </AlertDescription>
            </Alert>
          )}

          <Button type="submit" className="w-full" loading={isOtpValidating} size="lg">
            Continuar
          </Button>
          {triedToSubmit < 3 && (
            <Button onClick={resendOTP} variant="ghost" disabled={!canResend || isOtpValidating} type="button" className="mt-3 w-full">
              Reenviar {!canResend ? (timer < 10 ? `0${timer}s` : `${timer}s`) : ''}
            </Button>
          )}
        </Form>
      </div>
    </motion.div>
  );
}
