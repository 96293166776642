import { api } from './api';

export enum InviteValidationType {
  EMAIL = 'EMAIL',
  MATRICULA = 'REGISTRATION_NUMBER',
}

export type GetValidateCompanyResponse = {
  exists: boolean;
  name: string;
  id: string;
  corporateName: string;
  document: string;
  inviteValidationType: InviteValidationType;
  inviteCustomLabel?: string;
};

async function getValidateCompanyService(companyId: string): Promise<GetValidateCompanyResponse> {
  return api.get(`/v2/companies/${companyId}/exists`);
}

async function getSearchCompanyService(searchTerm: string): Promise<GetValidateCompanyResponse[]> {
  return api.get(`/v2/companies/search`, { params: { searchTerm } });
}

export { getValidateCompanyService, getSearchCompanyService };
