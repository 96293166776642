import { routeAnimationVariants } from '@public-pre-invite/router';
import { UserData, userDataAtom } from '../../atom/preInvite.atom';
import { GET_SEARCH_COMPANY, GET_VALIDATE_COMPANY } from '@public-pre-invite/consts/queries';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { debounce } from 'lodash';
import { BuildingIcon, InfoIcon, SearchIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { getSearchCompanyService, GetValidateCompanyResponse, getValidateCompanyService, InviteValidationType } from '../../services/company.service';
import { CompanyDetailsModal } from './components/CompanyDetailsModal';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  IconButton,
  Input,
  Loader,
  cn,
} from '@eyecarehealth/ds-aquilae-react';

export default function SearchCompanyPage() {
  const [value, setValue] = useState('');
  const [modalCompanyDetails, setModalCompanyDetails] = useState(false);
  const [dropdownCompanies, setDropdownCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<GetValidateCompanyResponse>(null);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const navigate = useNavigate();

  const companyId = useMemo(() => searchParams.get('companyId'), [searchParams]);

  const { data: validateCompanyById, isFetching: isValidateCompanyByIdFetching } = useQuery({
    queryKey: [GET_VALIDATE_COMPANY, { companyId }],
    queryFn: getValidateCompany,
    enabled: !!companyId,
  });

  const { data: companies, isFetching: isCompaniesFetching } = useQuery({
    queryKey: [GET_SEARCH_COMPANY, searchTerm],
    queryFn: getCompanies,
    enabled: searchTerm?.length > 2,
  });

  async function getValidateCompany() {
    try {
      const response = await getValidateCompanyService(companyId);
      setUserData({
        ...userData,
        selectedCompany: {
          ...response,
        },
      });
      navigate({
        pathname: '/identificador',
        search: createSearchParams({ companyId }).toString(),
      });
      return response;
    } catch (error) {}
  }

  async function getCompanies() {
    try {
      const response = await getSearchCompanyService(searchTerm);
      setDropdownCompanies(true);
      if (response.length) {
        const responseToSelectOptions = response.map((company) => ({ value: company.id, label: company.name }));
        setCompaniesList(responseToSelectOptions);
        return response;
      }
      setCompaniesList([]);
      return [];
    } catch (error) {
      setCompaniesList([]);
      return [];
    }
  }

  const debouncedSetSearchTerm = useCallback(
    debounce((newValue) => {
      setSearchTerm(newValue);
      setSelectedCompany(null);
    }, 1000),
    [],
  );

  function handleSelectCompanyToProceed(companyId: string) {
    const selectedCompanyToProceed = companies.find((company) => company.id === companyId);
    setUserData({
      ...userData,
      selectedCompany: {
        ...selectedCompanyToProceed,
      },
    });
    navigate('/identificador');
  }

  function handleCompanyDetails(companyId: string) {
    const selectedCompanyDetails = companies.find((company) => company.id === companyId);
    setSelectedCompany(selectedCompanyDetails);
    setModalCompanyDetails(true);
  }

  useEffect(() => {
    if (!modalCompanyDetails && companies?.length) {
      setDropdownCompanies(true);
    }
  }, [modalCompanyDetails]);

  useEffect(() => {
    if (!companyId)
      setUserData({
        ...userData,
        selectedCompany: {
          name: '',
          id: '',
          corporateName: '',
          document: '',
          inviteValidationType: InviteValidationType.EMAIL,
        },
      });
  }, []);

  return (
    <motion.div
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}
      animate="final"
      key={'searchCompany'}
      initial="initial"
      variants={routeAnimationVariants}>
      <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">
        Busque a empresa que oferece o <br />
        <span className="text-brand-primary whitespace-nowrap">Benefício Visão</span> para você!
      </h2>
      <span className="text-typography-secondary text-sm">Um benefício corporativo.</span>

      {isValidateCompanyByIdFetching ? (
        <div className="mt-4 flex items-center gap-3">
          <Loader text="Já identificamos sua empresa, aguarde..." />
        </div>
      ) : (
        <div className="relative mt-4">
          <div className="relative sm:max-w-[405px]">
            <SearchIcon className="text-brand-primary absolute left-2 top-[10px]" size={20} />
            <Input
              className="pl-8"
              type="text"
              placeholder="Encontre sua empresa..."
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                debouncedSetSearchTerm(e.target.value);
              }}
            />
            {isCompaniesFetching && (
              <div className="absolute right-2 top-[10px]">
                <Loader />
              </div>
            )}
          </div>
          <DropdownMenu onOpenChange={setDropdownCompanies} open={dropdownCompanies}>
            <DropdownMenuTrigger className="absolute w-full sm:w-[405px]">
              <div className="w-full" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="max-h-[200px] w-[405px] overflow-y-auto">
              {!companiesList?.length ? (
                <DropdownMenuLabel>
                  <span className="text-typography-secondary/80 py-3 font-semibold">Nenhuma empresa encontrada.</span>
                </DropdownMenuLabel>
              ) : (
                companiesList?.map((company) => (
                  <DropdownMenuItem key={company.value} className={cn(value === company.value && 'bg-brand-primary-100')}>
                    <button className="flex w-full items-center gap-2 py-2" onClick={() => handleSelectCompanyToProceed(company.value)}>
                      <BuildingIcon className="text-brand-primary" size={20} />
                      {company.label}
                    </button>
                    <IconButton variant="ghost" size="sm" className="ml-auto" onClick={() => handleCompanyDetails(company.value)}>
                      <InfoIcon size={16} className="text-brand-primary" />
                    </IconButton>
                  </DropdownMenuItem>
                ))
              )}
            </DropdownMenuContent>
          </DropdownMenu>
          <CompanyDetailsModal modal={modalCompanyDetails} setModal={setModalCompanyDetails} selectedCompany={selectedCompany} />
        </div>
      )}
    </motion.div>
  );
}
