import BVUtils from '@bv/utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@eyecarehealth/ds-aquilae-react';

export function EnrollmentDetailsModal() {
  if (BVUtils.device.IS_MOBILE) {
    return (
      <Drawer>
        <DrawerTrigger>
          <Button className="mb-4 px-0" type="button" variant="link">
            O que é o número de matrícula?
          </Button>
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Número de matrícula</DrawerTitle>
            <DrawerDescription>O que o número de matrícula?</DrawerDescription>
          </DrawerHeader>
          <div className="space-y-4 p-4 pt-0">
            <p className="text-typography-secondary">
              É o código que sua empresa usa para identificar você como colaborador. Pode incluir números, letras ou ambos.
            </p>
            <p className="text-typography-secondary">
              Se não souber o seu número de matrícula, entre em contato com a equipe do Benefício Visão (suporte@beneficiovisao.com.br) ou da sua
              empresa.
            </p>
          </div>
          <DrawerFooter>
            <DrawerClose>
              <Button variant="ghost" type="button" className="w-full">
                Fechar
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <Dialog>
      <DialogTrigger>
        <Button className="mb-4 px-0" type="button" variant="link">
          O que é o número de matrícula?
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Número de matrícula</DialogTitle>
          <DialogDescription>O que o número de matrícula?</DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <p className="text-typography-secondary">
            É o código que sua empresa usa para identificar você como colaborador. Pode incluir números, letras ou ambos.
          </p>
          <p className="text-typography-secondary">
            Se não souber o seu número de matrícula, entre em contato com a equipe do Benefício Visão (suporte@beneficiovisao.com.br) ou da sua
            empresa.
          </p>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button variant="ghost" type="button">
              Fechar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
