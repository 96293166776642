import { Alert, AlertDescription, AlertTitle, Button, EInput, Form, FormInput } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { routeAnimationVariants } from '@public-pre-invite/router';
import { postSendOtpService } from '@public-pre-invite/services/auth.service';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { InfoIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { UserData, userDataAtom } from '../../../../atom/preInvite.atom';
import { useMutation } from '@tanstack/react-query';
import { POST_SEND_OTP } from '@public-pre-invite/consts/queries';

const formSchema = z.object({
  email: z.string().email(),
});

export function EmailForm() {
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        email: z.string().email({ message: 'E-mail inválido' }),
      }),
    ),
  });
  const navigate = useNavigate();
  const [triedToSubmit, setTriedToSubmit] = useState(0);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);

  const { mutate: sendOtp, isPending: isSendingOtp } = useMutation({
    mutationKey: [POST_SEND_OTP],
    mutationFn: (data: z.infer<typeof formSchema>) => postSendOtpService({ email: data.email, companyId: userData.selectedCompany.id }),
    onError: (error) => {
      setTriedToSubmit((prev) => prev + 1);
    },
    onSuccess: (data) => {
      setUserData({ ...userData, email: methods.getValues('email') });
      navigate('/validar-otp');
    },
  });

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">Insira seu e-mail cadastrado</h2>
      <span className="text-typography-secondary text-sm">Vamos usar essa informação para confirmar que você foi adicionado ao nosso benefício.</span>

      <div className="mt-4 max-w-[405px]">
        <Form ctx={methods} onSubmit={sendOtp}>
          <FormInput name="email" label="" placeholder="E-mail cadastrado" type={EInput.EMAIL} />

          {triedToSubmit > 2 && (
            <Alert className="mb-4" variant="info">
              <InfoIcon size={20} className="text-brand-primary" />
              <AlertTitle className="text-brand-primary">Não conseguimos confirmar sua elegibilidade</AlertTitle>
              <AlertDescription>
                Certifique-se de que:
                <br />
                1. Você selecionou a empresa certa.
                <br />
                2. Você preencheu suas informações corretamente.
                <br />
                3. Sua empresa já adicionou você ao Benefício Visão.
                <br />
                Se o problema persistir, entre em contato conosco <a href="mailto:suporte@beneficiovisao.com.br">(suporte@beneficiovisao.com.br)</a>
              </AlertDescription>
            </Alert>
          )}

          <Button type="submit" className="w-full" loading={isSendingOtp} size="lg">
            Continuar
          </Button>
        </Form>
      </div>
    </motion.div>
  );
}
