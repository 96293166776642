import { motion } from 'framer-motion';
import { routeAnimationVariants } from '@public-pre-invite/router';
import * as singleSpa from 'single-spa';
import { useAtomValue } from 'jotai';
import { UserData, userDataAtom } from '../../atom/preInvite.atom';
import { Alert, AlertDescription, AlertTitle, Button } from '@eyecarehealth/ds-aquilae-react';

export function Success() {
  const userData = useAtomValue<UserData>(userDataAtom);

  return (
    <motion.div
      animate="final"
      initial="initial"
      exit="exit"
      variants={routeAnimationVariants}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}>
      <div className="flex flex-col gap-8 sm:max-w-[70%]">
        <Alert variant="info">
          <AlertTitle>Parabéns!</AlertTitle>
          <AlertDescription>
            Agora você pode iniciar seu cadastro no <strong>Benefício Visão</strong>.
          </AlertDescription>
        </Alert>
        <span className="text-typography-secondary text-sm">
          Vamos usar essa informação para confirmar que você foi adicionado ao nosso benefício.
        </span>
        <Button className="w-full" onClick={() => singleSpa.navigateToUrl(userData.inviteUrl)} size="lg">
          Fazer cadastro
        </Button>
      </div>
    </motion.div>
  );
}
