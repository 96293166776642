import { AnimatePresence } from 'framer-motion';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { Layout } from '../components/Layout';
import IdentifierPage from '../pages/Identifier';
import OTPValidationPage from '../pages/OTPValidation';
import SearchCompanyPage from '../pages/SearchCompany';
import { Success } from '../pages/Success';

export const routeAnimationVariants = {
  initial: {
    opacity: 0,
    y: -24,
  },
  final: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 16,
  },
};

export const router: any = createBrowserRouter(
  [
    {
      element: (
        <Layout>
          <AnimatePresence>
            <Outlet />
          </AnimatePresence>
        </Layout>
      ),
      children: [
        {
          path: '/',
          element: <SearchCompanyPage />,
        },
        {
          path: '/identificador',
          element: <IdentifierPage />,
        },
        {
          path: '/validar-otp',
          element: <OTPValidationPage />,
        },
        {
          path: '/sucesso',
          element: <Success />,
        },
      ],
    },
  ],
  { basename: '/pre-convite' },
);
